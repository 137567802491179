import React, { useEffect, useRef } from 'react';
import * as joint from '@joint/core';
import {WorkspaceData} from './types'

interface WorkflowProps {
  workspaceData: WorkspaceData;
}

const Workflow: React.FC<WorkflowProps> = ({ workspaceData }) => {
  const paperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!paperRef.current) return;

    const graph = new joint.dia.Graph();
    const paper = new joint.dia.Paper({
      el: paperRef.current,
      model: graph,
      width: '100%',
      height: 1750,
      gridSize: 10,
      drawGrid: true,
      background: {
        color: '#F8F9FA',
      },
    });

    const tasks = workspaceData.workspace.tasks;
    const elements: joint.dia.Element[] = [];

    const colorMap: { [key: string]: string } = {
      script: '#4B8BBE',
      ai_agent_task: '#FF6B6B',
      user_agent_task: '#4ECDC4',
    };

    // Create a map of task names to their corresponding elements
    const taskElementMap = new Map<string, joint.dia.Element>();

    tasks.forEach((task, index) => {
      const rect = new joint.shapes.standard.Rectangle({
        position: { x: 50, y: 50 + index * 120 },
        size: { width: 200, height: 80 },
        attrs: {
          body: {
            fill: colorMap[task.type] || '#A9A9A9',
            stroke: '#2F4F4F',
          },
          label: {
            text: joint.util.breakText(task.task_name, { width: 190 }),
            fill: 'white',
            fontSize: 14,
          },
        },
      });

      rect.addTo(graph);
      elements.push(rect);
      taskElementMap.set(task.task_name, rect);

      if (index > 0) {
        const link = new joint.shapes.standard.Link({
          source: { id: elements[index - 1].id },
          target: { id: rect.id },
          router: { name: 'orthogonal' },
          connector: { name: 'rounded' },
        });
        link.addTo(graph);
      }

      if (task.type === 'user_agent_task' && task.condition) {
        task.condition.forEach((condition, condIndex) => {
          const conditionRect = new joint.shapes.standard.Rectangle({
            position: { x: 300, y: 50 + index * 120 + condIndex * 70 },
            size: { width: 150, height: 50 },
            attrs: {
              body: {
                fill: '#FFD43B',
                stroke: '#FFE873',
              },
              label: {
                text: joint.util.breakText(condition.description, { width: 140 }),
                fill: 'black',
                fontSize: 12,
              },
            },
          });
          conditionRect.addTo(graph);

          const conditionLink = new joint.shapes.standard.Link({
            source: { id: rect.id },
            target: { id: conditionRect.id },
            router: { name: 'orthogonal' },
            connector: { name: 'rounded' },
            labels: [{ position: 0.5, attrs: { text: { text: condition.if } } }],
          });
          conditionLink.addTo(graph);

          const nextTaskElement = taskElementMap.get(condition.next_task);
          if (nextTaskElement) {
            const nextTaskLink = new joint.shapes.standard.Link({
              source: { id: conditionRect.id },
              target: { id: nextTaskElement.id },
              router: { name: 'orthogonal' },
              connector: { name: 'rounded' },
            });
            nextTaskLink.addTo(graph);
          } else {
            console.warn(`Next task "${condition.next_task}" not found for condition in task "${task.task_name}"`);
          }
        });
      }
    });

    paper.on('element:pointerclick', (elementView: joint.dia.ElementView) => {
      const element = elementView.model;
      const taskName = element.attr('label/text');
      const task = tasks.find(t => t.task_name === taskName);
      if (task) {
        console.log('Task details:', task);
        // You can show task details in a modal or sidebar here
      }
    });

    return () => {
      paper.remove();
    };
  }, [workspaceData]);

  return <div ref={paperRef} style={{ width: '100%', height: '800px' }} />;
};

export {Workflow};