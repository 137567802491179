/**
 * Send User Message (On Submit)
 **/
export const sendMessage = async (
  endpoint: string | undefined = 'chat',
  apiUrl: string | undefined,
  token: string | undefined,
  message: string,
  file: File | null,
  jobId: string | undefined = undefined,
  userId: string | undefined = undefined,
  agentId: string | undefined = undefined,
  companyId: string | undefined = undefined,
  collectionId: string | undefined = undefined,
) => {
  const formData = new FormData()
  formData.append('message', message)
  formData.append('user_message', message)
  formData.append('verbose', 'true')

  if (file) formData.append('file', file)
  if (token) formData.append('token', token)

  if (!endpoint) endpoint = 'chat'

  if (endpoint === 'chat') {

    // Metis & IO General Chat
    if (jobId) formData.append('job_id', jobId)
    if (agentId) formData.append('agent_id', agentId)

  } else if (endpoint === 'chatbot-chat') {

    // Metis Report Chat
    if (jobId) formData.append('job_id', jobId)
    if (agentId) formData.append('agent_id', agentId)

  } else if (endpoint === 'company') {

    // Company Chat
    if (companyId) formData.append('company_id', companyId)
    if (userId) formData.append('user_id', userId)
    if (agentId) formData.append('agent_id', agentId)

  } else if (endpoint === 'knowledge') {

    // Collection Chat
    if (collectionId) formData.append('collection_id', collectionId)
    if (userId) formData.append('user_id', userId)
    if (agentId) formData.append('agent_id', agentId)

  } else if (endpoint === 'general') {

    // General Chat
    if (userId) formData.append('user_id', userId)

  }

  if (endpoint !== 'chat' && endpoint !== 'chatbot-chat') {
    endpoint = `chat-${endpoint}`
  }

  const response = await fetch(`${apiUrl}/${endpoint}`, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to send message')
  }

  return response.json()
}

/**
 * Send Initial Message (On Load)
 **/
export const sendInitialize = async (
  endpoint: string | undefined = 'chat',
  apiUrl: string | undefined,
  token: string | undefined,
  jobId: string | undefined = '',
  userId: string | undefined = '',
  agentId: string | undefined = '',
  companyId: string | undefined = '',
  collectionId: string | undefined = '',
) => {
  const formData = new FormData()

  let message = ''
  if (token) formData.append('token', token)

  if (!endpoint) endpoint = 'chat'

  if (endpoint === 'chat') {

    // Metis & IO General Chat
    if (jobId) formData.append('job_id', jobId)
    if (agentId) formData.append('agent_id', agentId)

  } else if (endpoint === 'chatbot-chat') {

    // Metis Report Chat
    if (jobId) formData.append('job_id', jobId)
    if (agentId) formData.append('agent_id', agentId)

  } else if (endpoint === 'company') {

    // Company Chat
    if (companyId) formData.append('company_id', companyId)
    if (userId) formData.append('user_id', userId)
    if (agentId) formData.append('agent_id', agentId)
    message = 'Hello how can you assist me?'

  } else if (endpoint === 'knowledge') {

    // Collection Chat
    if (collectionId) formData.append('collection_id', collectionId)
    if (userId) formData.append('user_id', userId)
    if (agentId) formData.append('agent_id', agentId)
    message = 'Hello how can you assist me?'

  } else if (endpoint === 'general') {

    // General Chat
    if (userId) formData.append('user_id', userId)
    message = 'Hello how can you assist me?'

  }

  if (message) formData.append('message', message)

  if (endpoint !== 'chat' && endpoint !== 'chatbot-chat') {
    endpoint = `chat-${endpoint}`
  }

  const response = await fetch(`${apiUrl}/${endpoint}`, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to initialize chat')
  }

  return response.json()
}
