import React, { FC, useEffect, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { CardStatus, CardRecords, CardJobs, CardStatistics } from '../../partials/widgets/dashboard'
import { IJobDetails } from '../../models/JobModel'
import { useNavigate } from 'react-router-dom'
import IPConfig from '../../store/IPConfig'

const fetchData = async (url) => {
  const response = await fetch(url)
  if (!response.ok) throw new Error('Network response was not ok')
  return response.json()
}

const DashboardPage: FC = () => {
  const apiUrl = IPConfig()
  const navigate = useNavigate()

  const [user, setUser] = useState<any>(null)
  const [statistics, setStatistics] = useState({
    jobsTotal: 0,
    jobsPending: 0,
    jobsProcessing: 0,
    jobsCompleted: 0,
    tasksTotal: 0,
    tasksPending: 0,
    tasksProcessing: 0,
    tasksCompleted: 0,
    users: 0,
    companies: 0,
    stripe: [],
    ai: { agent: [], chatbot: [] },
    google: [],
  })

  const [jobArray, setJobArray] = useState<IJobDetails[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 6,
  })

  useEffect(() => {
    const userUpdated = JSON.parse(window.localStorage.getItem('userUpdated') || '{}')
    setUser(userUpdated)
  }, [])

  const fetchDashboardData = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await fetchData(`${apiUrl}/dashboard`)
      if (data.status === 'success') {
        const { stripe, ai, google, jobs, users, companies, user_tasks } = data.data

        setStatistics({
          jobsTotal: jobs.total || 0,
          jobsPending: jobs.total_pending || 0,
          jobsProcessing: jobs.total_processing || 0,
          jobsCompleted: jobs.total - jobs.total_pending - jobs.total_processing || 0,
          tasksTotal: user_tasks.total || 0,
          tasksPending: user_tasks.total_pending || 0,
          tasksProcessing: user_tasks.total - user_tasks.total_completed - user_tasks.total_pending || 0,
          tasksCompleted: user_tasks.total_completed || 0,
          users: users.total || 0,
          companies: companies.total || 0,
          stripe,
          ai,
          google,
        })

        setJobArray(jobs.recent || [])
        setPagination((prev) => ({
          ...prev,
          total: jobs.total || 0,
        }))
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error)
      setJobArray([])
    } finally {
      setIsLoading(false)
    }
  }, [apiUrl])

  useEffect(() => {
    if (user) {
      fetchDashboardData()
    }
  }, [user, fetchDashboardData])

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-4'>
              <CardStatistics
                title='Stripe Statistics'
                description='Recent sales statistics'
                className='card-xl-stretch mb-xl-8'
                chartColor='primary'
                chartHeight='150px'
                data={statistics.stripe}
              />
            </div>
            <div className='col-xl-4'>
              <CardStatistics
                title='AI Model Statistics'
                description='Token usage statistics'
                className='card-xl-stretch mb-xl-8'
                chartColor='success'
                chartHeight='150px'
                data={statistics.ai}
              />
            </div>
            <div className='col-xl-4'>
              <CardStatistics
                title='Google Analytics'
                description='Analytical statistics'
                className='card-xl-stretch mb-xl-8'
                chartColor='danger'
                chartHeight='150px'
                data={statistics.google}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardStatus
                className='h-md-50 mb-5 mb-xl-10'
                description='Completed Tasks'
                color='#F1416C'
                stats={statistics}
                type='tasks'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
              <CardRecords
                className='h-md-50 mb-5 mb-xl-10'
                description='Total Users'
                icon={false}
                stats={statistics.users}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardStatus
                className='h-md-50 mb-5 mb-xl-10'
                description='Completed Jobs'
                color='#F1416C'
                stats={statistics}
                type='jobs'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
              <CardRecords
                className='h-md-50 mb-5 mb-xl-10'
                description='Total Companies'
                icon={false}
                stats={statistics.companies}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-xxl-6'>
              <CardJobs
                jobs={jobArray}
                className="card-xxl-stretch mb-xl-3 h-md-100"
                refreshJobs={fetchDashboardData}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
