import React, { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Chatbot } from '../../../libraries/react-chatbot/src/components'
import IPConfig from '../../store/IPConfig'

const ChatbotPage: FC = () => {
  const [token, setToken] = useState('')
  const [selectedPersonaName, setSelectedPersonaName] = useState('Chatbot')
  const [selectedPersonaAvatar, setSelectedPersonaAvatar] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    // Get token from query params
    const urlParams = new URLSearchParams(window.location.search)
    const tokenFromParams = urlParams.get('token')
    if (tokenFromParams) {
      setToken(tokenFromParams)
    } else {
      navigate('/auth/login')
    }
  }, [])

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-center">
      <div className='flex-lg-row-fluid col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style={{ maxWidth: '1200px' }}>
        <Chatbot
          apiUrl={IPConfig()}
          personaName={selectedPersonaName}
          personaAvatar={selectedPersonaAvatar}
          setPersonaName={setSelectedPersonaName}
          setPersonaAvatar={setSelectedPersonaAvatar}
          token={token}
        />
      </div>
    </div>
  )
}

export {ChatbotPage}