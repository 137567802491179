/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Workflow} from "./Workflow";
import {WorkspaceData} from './types'

const workspaceData: WorkspaceData = {
  workspace: {
    name: "Investor Analysis : Metis",
    description: "This is the initial review of a recently received startup pitch deck to enable an investor to coach a founder on which areas of the pitch are strong, which are weak, what the overall investability of the opportunity is and what can be done to improve the quality of the pitch. This workspace does not OCR first, but rather only performs an initial review on it and generates a 5-10 page A4 report for the founder to review.",
    timeout: 10000,
    permission: "Metis",
    input_documents: [
      { name: "pitch_deck.pdf", type: "pdf", description: "Pitch Deck pdf file" },
      { name: "investor_mandate.json", type: "json", description: "Mandate for Investor" }
    ],
    input_variables: [
      { name: "email_address", description: "Email address of the investor" },
      { name: "company_name", description: "Name of the company of this ocr'd file" }
    ],
    tasks: [
      {
        task_name: "OCR Pitch Deck",
        type: "script",
        method: "ocr-claude",
        description: "OCR the pdf document",
        input_documents: ["pitch_deck.pdf"],
        output_temp_document: { name: "data_ocr.md", overwrite: true }
      },
      {
        task_name: "Get Company Info",
        type: "script",
        method: "metis-process",
        description: "Either update a temporary companies information or just get the company name from the Pitch Deck",
        input_documents: ["data_ocr.md"],
        output_temp_document: { name: "company_info.txt", overwrite: true }
      },
      {
        task_name: "Series Readiness",
        type: "ai_agent_task",
        agent_name: "Claude Agent [Llew Coach v2.0]",
        description: "Analyse the ocr'd document using the mandate file.",
        instruction: "Please do a simple analysis of this document using the information in this mandate file to help you: {investor_mandate.json}.",
        output_template: "## Series Readiness\n{output}",
        input_documents: ["data_ocr.md"],
        output_temp_document: { name: "Sv0.5Memo.md", overwrite: true }
      },
      {
        task_name: "validate report",
        type: "user_agent_task",
        description: "Review the ocr and report and validate that all is correct",
        instruction: "Markdown report is editable, review the report and make any changes that are needed.",
        condition: [
          {
            if: "reocr",
            description: "reOCR Pitch Deck and rerun Workflow",
            next_task: "OCR Pitch Deck"
          },
          {
            if: "happy",
            description: "All seems good",
            next_task: "Get Scores"
          }
        ],
        input_documents: ["pitch_deck.pdf", "data_ocr.md", "Sv0.5Memo.md"],
        input_template: "{Sv0.5Memo.md}",
        output_document: {
          name: "Sv0.5Memo.md",
          overwrite: true,
          primary: true,
          metadata: { company_name: "{company_name}" }
        },
        user_role: "admin"
      },
      {
        task_name: "Get Scores",
        type: "ai_agent_task",
        agent_name: "Claude Agent [Llew Coach v2.0]",
        description: "This task will grab all the scores out of the document",
        instruction: "From the document below. Get me the score from the 'Key Success Factors' section for each of these indicators: 'ADOPTION', 'CUSTOMER ENGAGEMENT, 'PRODUCT STATUS', 'TECHNOLOGY ADVANTAGE', 'MARKET POTENTIAL', 'PROTECTABILITY', 'ROUTE TO MARKET', 'RELEVANT EXPERIENCE', 'FINANCIALS' and 'FOUNDER INCENTIVIZATION'. You have to return the content in json format with each indicator as the key and the score as the value. Leave the spaces within the indicators. Also within the json object add another field called 'MESSAGE' and write a short 50 word summary of the report. Only reply with the json object and no other text.",
        input_documents: ["Sv0.5Memo.md"],
        output_template: "# Scores for Report\n\n{output}",
        output_document: {
          name: "Sv0.5Memo_Scores.md",
          metadata: { company_name: "{company_name}" }
        }
      },
      {
        task_name: "send email",
        type: "script",
        description: "Send an email with the response",
        input_documents: ["*.md"],
        output_temp_document: "sent_email.md",
        method: {
          name: "email",
          parameters: [
            {
              email_address: "{email_address}",
              template: "Report Ready"
            }
          ]
        }
      }
    ]
  }
};

const WorkflowPage: FC = () => {
  const [currentWorkspace, setCurrentWorkspace] = useState(workspaceData);

  const handleWorkspaceUpdate = (updatedWorkspace: WorkspaceData) => {
    setCurrentWorkspace(updatedWorkspace);
    console.log("updatedWorkspace");
    console.log(updatedWorkspace);
    // Here you can also send the updated workspace to your backend if needed
  };

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <Workflow workspaceData={currentWorkspace} />
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};

const WorkflowWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.WORKFLOW'})}</PageTitle>
      <WorkflowPage />
    </>
  )
}

export {WorkflowWrapper}