import React from "react";
import { useChatbot } from "../../hooks";
import { ChatMessage } from "./ChatMessage";
import { ChatInput } from "./ChatInput";
import { ChatFooter } from "./ChatFooter";
import { ChatbotProps } from "../../types";

const Chatbot: React.FC<ChatbotProps> = ({
  isDrawer = false,
  noFooter = false,
  endpoint = "chat",
  apiUrl = "https://io-dev-api.metalogix.solutions",
  publicUrl = "https://io-dev.metalogix.solutions",
  personaName = "Chatbot",
  personaAvatar = "media/avatars/blank.png",
  selectedAgent,
  setPersonaName,
  setPersonaAvatar,
  token = "123",
  inputColor = "",
  bgColor = "",
}) => {
  const {
    messages,
    isLoadingMessage,
    chatCompleted,
    textareaHeight,
    scrollRef,
    message,
    setMessage,
    handleFormSubmit,
    handleLike,
    handleDislike,
    handleReadAloud,
    handleCopy,
    personaName: currentPersonaName,
    personaAvatar: currentPersonaAvatar,
    userName,
  } = useChatbot({
    endpoint,
    apiUrl,
    publicUrl,
    selectedAgent,
    personaName,
    personaAvatar,
    setPersonaName,
    setPersonaAvatar,
    token,
  });

  return (
    <div
      id="kt_chat_messenger"
      className='border-0'
      style={{
        background: `${bgColor ? bgColor : '#171717'}`
      }}>
      <div
        className={
          noFooter ? 'card-body card-chat pb-0 mb-6' : 'card-body card-chat pb-0 mb-0'
        }
        id={
          isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"
        }
      >
        <div className="d-flex flex-column h-100">
          <div
            className="flex-grow-1 overflow-auto"
            style={{
              maxHeight: `calc(100vh - ${(noFooter ? 133 : 120) + (textareaHeight > 40 ? textareaHeight - 8 : textareaHeight)}px)`,
              minHeight: `calc(100vh - ${(noFooter ? 133 : 120) + (textareaHeight > 40 ? textareaHeight - 8 : textareaHeight)}px)`,
            }}
            ref={scrollRef}
          >
            {messages.map((message: any, index: number) => (
              <ChatMessage
                key={`message${index}`}
                message={message}
                index={index}
                isDrawer={isDrawer}
                personaName={currentPersonaName}
                personaAvatar={currentPersonaAvatar}
                userName={userName}
                publicUrl={publicUrl}
                onLike={handleLike}
                onDislike={handleDislike}
                onReadAloud={handleReadAloud}
                onCopy={handleCopy}
                inputColor={inputColor}
              />
            ))}
            {isLoadingMessage && <div className="mt-10 ms-10 loader"></div>}
          </div>

          <ChatInput
            isDrawer={isDrawer}
            isLoadingMessage={isLoadingMessage}
            setMessage={setMessage}
            message={message}
            chatCompleted={chatCompleted}
            onSubmit={handleFormSubmit}
            inputColor={inputColor}
          />

          {!noFooter && (
            <ChatFooter />
          )}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
