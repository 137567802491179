/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import IPConfig from '../../../store/IPConfig'
import {dispatchStorageEvent, KTIcon} from '../../../../_metronic/helpers'
import {ModalDeleteConfirm} from '../../../partials/modals/ModalDeleteConfirm'
import {CardDocument} from './CardDocument'

type Props = {
  className: string
}

const ViewCollection: React.FC<Props> = ({className}) => {
  const [successMessage, setSuccessMessage] = useState('')
  const [failMessage, setFailMessage] = useState('')
  const [validationError, setValidationError] = useState('')
  const [currentDocument, setCurrentDocument] = useState<any>(null)

  const [selectedUpload, setSelectedUpload] = useState('')
  const [selectedFiles, setSelectedFiles]: any = useState()
  const [filesToAdd, setFilesToAdd]: any = useState([])

  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [removeItem, setRemoveItem] = useState(0)
  const [deleteMessage, setDeleteMessage] = useState('')
  const [confirmRemoveModalVisible, setConfirmRemoveModalVisible] = useState(false)
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  const apiUrl = IPConfig()
  const navigate = useNavigate()

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    setUser(userUpdated)
  }, [])

  useEffect(() => {
    const storedDocument: any = localStorage.getItem('currentDocument')
    if (storedDocument) {
      setCurrentDocument(JSON.parse(storedDocument))
    }
  }, [])

  useEffect(() => {
    fetchUserFiles(currentDocument)
  }, [currentDocument])

  useEffect(() => {
    if (currentDocument) {
      if (currentDocument.id) {
        setSelectedUpload(currentDocument.collection_name)
        window.localStorage.setItem('selectedCollectionId', currentDocument.id);
        dispatchStorageEvent('selectedCollectionId', currentDocument.id);
      } else {
        window.localStorage.setItem('selectedCollectionId', '');
        dispatchStorageEvent('selectedCollectionId', '');
      }
    }
  }, [currentDocument])

  const fetchUserFiles = (currentDocument) => {
    if (currentDocument) {
      fetch(
        `${apiUrl}/getfilesbycollection?all=true&name=${currentDocument.collection_name}&page=1&items_per_page=1000`
      )
        .then((response) => response.json())
        .then((response) => {
          const newArray: any = []

          if (response.data) {
            response.data.forEach((element) => {
              if (element.collection !== '') {
                newArray.push(element)
              }
            })

            setSelectedFiles([...newArray])
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const validateInputs = () => {
    /*if (!name.trim()) {
      setValidationError("All fields are required.");
      return false;
    }*/
    setValidationError('')
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFailMessage('')
    setSuccessMessage('')
    setValidationError('')
    if (validateInputs()) {
      //updateDocument();
    }
  }

  function bytesToSize(bytes) {
    const kilobyte = 1024
    const megabyte = kilobyte * 1024
    const gigabyte = megabyte * 1024

    if (bytes < kilobyte) {
      return bytes + ' Bytes'
    } else if (bytes < megabyte) {
      return (bytes / kilobyte).toFixed(2) + ' KB'
    } else if (bytes < gigabyte) {
      return (bytes / megabyte).toFixed(2) + ' MB'
    } else {
      return (bytes / gigabyte).toFixed(2) + ' GB'
    }
  }

  const handleNewDocumentSubmit = (file) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file_label', file.name)
    formData.append('file', file, file.name)
    formData.append('file_description', 'test')
    formData.append('collection_name', selectedUpload)
    formData.append('user_id', user.id)

    fetch(`${apiUrl}/upload`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        /* getFiles() */
        fetchUserFiles(currentDocument)
        setIsLoading(false)
      })
      .catch((error) => {
        alert('Failed to upload file')
        setIsLoading(false)
        console.error(error)
      })
  }

  const handleFileSelect = (e) => {
    if (!filesToAdd[0]) {
      const files: any = Array.from(e.target.files)

      // Filter the dropped files to only include .txt and .pdf files
      const validFiles: any = files.filter((file) => {
        const fileType = file.type
        const fileName = file.name.toLowerCase()
        return fileType === 'text/plain' || fileName.endsWith('.pdf') || fileName.endsWith('.md')
      })

      const newArray = filesToAdd

      if (validFiles[0]) {
        newArray.push(validFiles[0])
        setFilesToAdd([...newArray])
      } else {
        alert('Invalid file type.')
      }
    } else {
      alert('You may only upload one file at a time.')
    }
  }

  const saveFiles = () => {
    filesToAdd.forEach((file) => {
      handleNewDocumentSubmit(file)
    })
    setFilesToAdd([])
  }
  // Function to handle file drop
  const handleFileDrop = (e) => {
    if (!filesToAdd[0]) {
      e.preventDefault()
      const droppedFiles: any = Array.from(e.dataTransfer.files)

      // Filter the dropped files to only include .txt and .pdf files
      const validFiles: any = droppedFiles.filter((file) => {
        const fileType = file.type
        const fileName = file.name.toLowerCase()
        return fileType === 'text/plain' || fileName.endsWith('.pdf') || fileName.endsWith('.md')
      })

      const newArray = filesToAdd

      if (validFiles[0]) {
        newArray.push(validFiles[0])
        setFilesToAdd([...newArray])
      } else {
        alert('Invalid file type.')
      }
    } else {
      alert('You may only upload one file at a time.')
    }
  }

  // Function to prevent the default behavior of file drop
  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const deleteDocument = async (documentId) => {
    try {
      const response = await fetch(`${apiUrl}/deletefilebyid?id=${documentId}`, {
        method: 'DELETE',
      })

      if (response.status === 200) {
        /*  fetchUserFiles(currentDocument) */
        const newArray = []
        selectedFiles.forEach((element) => {
          if (element.id !== documentId)
            //@ts-ignore
            newArray.push(element)
        })
        setSelectedFiles([...newArray])
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      alert('Failed to delete file')
    }
  }

  const removeDocument = (i) => {
    const newArray = filesToAdd

    newArray.splice(i, 1)

    setFilesToAdd([...newArray])
    setConfirmRemoveModalVisible(false)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1 text-capitalize'>
            {selectedUpload.replaceAll('_', ' ')} Collection
          </span>
          <span className='card-label fw-bolder fs-6 mb-1 text-muted'>
            Upload Document to <span className='text-capitalize'>{selectedUpload.replaceAll('_', ' ')}</span>
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pb-3 pt-6'>
        <div className='file-uploader'>
          <div
            className='dropzone drop-area mb-10'
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
          >
            <div className='back-flex'>
              <input
                className='form-control form-control-solid choose-file'
                type='file'
                accept='.pdf, .txt, .md'
                multiple
                onChange={handleFileSelect}
              />
            </div>

            <div className='drop-box mt-6'>
              <span className='ki-solid ki-file-up fs-6x'></span>
              <p>Drag and Drop files</p>

              {filesToAdd && filesToAdd[0] && <h3 className={`mt-6`}>Added files:</h3>}
              {filesToAdd &&
                filesToAdd.map((file, index) => (
                  <>
                    <div key={index}>
                      {file.name} - {bytesToSize(file.size)}
                    </div>
                    {' '}
                    <button
                      className='btn btn-primary mt-3 remove-addition'
                      onClick={() => {
                        setConfirmRemoveModalVisible(true)
                        setRemoveItem(index)
                        setDeleteMessage('Are you sure you want to remove the file added?')
                      }}
                    >
                      Remove
                    </button>
                  </>
                ))}
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-4">Saved Files</label>
            {/* end::Label */}

            <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
              {selectedFiles && selectedFiles.map((file) => (
                file && file.file_label && (
                  <div className="col-12 col-sm-12 col-xl" key={file.id}>
                    <CardDocument
                      title={file.file_label.replaceAll('.md', '').replaceAll('.pdf', '').replaceAll('.txt', '').replaceAll('+', ' ')}
                      description={file.date_created}
                      file={file}
                      setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                      setDeleteItem={setDeleteItem} />
                  </div>
                )
              ))}
            </div>

          </div>
          {/* end::Input group */}

          {validationError && (
            <p className="form-field-feedback text-danger fail">{validationError}</p>
          )}
          {successMessage && (
            <p className="form-field-feedback text-success success">{successMessage}</p>
          )}
          {failMessage && <p className="form-field-feedback text-danger fail">{failMessage}</p>}

          <input
            type={'button'}
            className="btn btn-light-primary fw-bolder mb-8 me-3 modal-button-back float-start"
            value={'Back'}
            onClick={() => navigate('/documents')}
          />

          <button
            type="button"
            value={'Save'}
            onClick={() => filesToAdd.length > 0 && saveFiles()}
            className={`btn btn-light-primary fw-bolder mb-8 ms-3 modal-button-save ${
              filesToAdd.length === 0 ? 'modal-button-disabled' : ''
            }`}
          >
            {!isLoading ? (
              'Save'
            ) : (
              <span className='spinner-border spinner-border-sm align-middle '></span>
            )}
          </button>
        </form>
      </div>
      {/* begin::Body */}

      {confirmDeleteModalVisible && (
        <ModalDeleteConfirm
          show={confirmDeleteModalVisible}
          onClose={() => setConfirmDeleteModalVisible(false)}
          onConfirm={() => {
            setConfirmDeleteModalVisible(false)
            deleteDocument(deleteItem[1])
          }}
          message={'Are you sure you want to delete ' + deleteItem[0] + '?'}
        />
      )}
      {confirmRemoveModalVisible && (
        <ModalDeleteConfirm
          show={confirmRemoveModalVisible}
          onClose={() => setConfirmRemoveModalVisible(false)}
          onConfirm={() => {
            setConfirmDeleteModalVisible(false)
            removeDocument(removeItem)
          }}
          message={'Are you sure you want to delete ' + filesToAdd[removeItem].name + '?'}
        />
      )}
    </div>
  )
}

export {ViewCollection}
