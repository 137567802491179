/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTIcon } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials'
import { FilterTasks } from './FilterTasks'

type CardStatisticsProps = {
  title: string
  description: string
  className: string
  chartColor: string
  chartHeight: string
  data: any
}

const CardStatistics: React.FC<CardStatisticsProps> = ({ title, description, className, chartColor, chartHeight, data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current || !data) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])

  const getStatistics = () => {
    if (!data) return []

    if (title === 'Stripe Statistics' && Array.isArray(data) && data.length > 0) {
      const latestData = data[data.length - 1]
      return [
        { label: 'Succeeded', value: latestData.succeeded || 0 },
        { label: 'Refunded', value: latestData.refunded || 0 },
        { label: 'Failed', value: latestData.failed || 0 },
        { label: 'Uncaptured', value: latestData.uncaptured || 0 },
      ]
    } else if (title === 'AI Model Statistics' && data.agent && data.chatbot) {
      const agentTotal = data.agent.reduce((sum, item) => sum + (item.tokens || 0), 0)
      const chatbotTotal = data.chatbot.reduce((sum, item) => sum + (item.tokens || 0), 0)
      const agentCost = data.agent.reduce((sum, item) => sum + (item.cost || 0), 0)
      const chatbotCost = data.chatbot.reduce((sum, item) => sum + (item.cost || 0), 0)
      return [
        { label: 'Agent Total Tokens', value: agentTotal },
        { label: 'Chatbot Total Tokens', value: chatbotTotal },
        { label: 'Agent Total Cost', value: `$${agentCost.toFixed(2)}` },
        { label: 'Chatbot Total Cost', value: `$${chatbotCost.toFixed(2)}` },
      ]
    } else if (title === 'Google Analytics' && Array.isArray(data) && data.length > 0) {
      const latestData = data[data.length - 1]
      return [
        { label: 'Pageviews', value: latestData.pageviews || 0 },
        { label: 'Sessions', value: latestData.sessions || 0 },
        { label: 'Bounce Rate', value: `${latestData.bounce_rate || 0}%` },
      ]
    }
    return []
  }

  const statistics = getStatistics()

  if (!data) {
    return <div>Loading...</div>
  }

  return (
    <div className={`card ${className} card-statistics`}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{title}</span>
          <span className="text-muted fw-semibold fs-7">{description}</span>
        </h3>

        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <FilterTasks />
          {/* end::Menu */}
        </div>
      </div>

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column'>
        {/* begin::Stats */}
        <div className='card-px pt-5 pb-10 flex-grow-1'>
          {/* begin::Row */}
          <div className='row g-0 mt-5 mb-10'>

            {/* begin::Col */}
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-info'>
                    <KTIcon iconName='bucket' className='fs-1 text-info' />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div className='fs-4 text-dark fw-bold'>$2,034</div>
                  <div className='fs-7 text-muted fw-semibold'>Author Sales</div>
                </div>
                {/* end::Title */}
              </div>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-danger'>
                    <KTIcon iconName='abstract-26' className='fs-1 text-danger' />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div className='fs-4 text-dark fw-bold'>$706</div>
                  <div className='fs-7 text-muted fw-semibold'>Commision</div>
                </div>
                {/* end::Title */}
              </div>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-success'>
                    <KTIcon iconName='basket' className='fs-1 text-success' />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div className='fs-4 text-dark fw-bold'>$49</div>
                  <div className='fs-7 text-muted fw-semibold'>Average Bid</div>
                </div>
                {/* end::Title */}
              </div>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-primary'>
                    <KTIcon iconName='cheque' className='fs-1 text-primary' />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div className='fs-4 text-dark fw-bold'>$5.8M</div>
                  <div className='fs-7 text-muted fw-semibold'>All Time Sales</div>
                </div>
                {/* end::Title */}
              </div>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-6-chart card-rounded-bottom'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, data: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

  let series: ApexAxisChartSeries = []
  let categories: string[] = []

  if (Array.isArray(data) && data.length > 0) {
    // Stripe or Google Analytics data
    series = [{
      name: 'Total',
      data: data.map(item =>
        Object.values(item)
          .filter((value): value is number => typeof value === 'number')
          .reduce((a, b) => a + b, 0)
      )
    }]
    categories = data.map((_, index) => `Day ${index + 1}`)
  } else if (data && data.agent && data.chatbot) {
    // AI Model data
    series = [
      {
        name: 'Agent',
        data: data.agent.map((item: any) => typeof item.tokens === 'number' ? item.tokens : 0)
      },
      {
        name: 'Chatbot',
        data: data.chatbot.map((item: any) => typeof item.tokens === 'number' ? item.tokens : 0)
      }
    ]
    categories = data.agent.map((_: any, index: number) => `Day ${index + 1}`)
  }

  return {
    series,
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: { show: false },
      zoom: { enabled: false },
      sparkline: { enabled: true },
    },
    plotOptions: {},
    legend: { show: true },
    dataLabels: { enabled: false },
    fill: { type: 'solid', opacity: 0.3 },
    stroke: { curve: 'smooth', show: true, width: 3 },
    xaxis: {
      categories,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: false },
      crosshairs: { show: false, position: 'front', stroke: { color: strokeColor, width: 1, dashArray: 3 } },
      tooltip: { enabled: false },
    },
    yaxis: {
      min: 0,
      max: Math.max(...series.flatMap(s => s.data.map(v => typeof v === 'number' ? v : 0))) * 1.2,
      labels: { show: false },
    },
    states: {
      normal: { filter: { type: 'none', value: 0 } },
      hover: { filter: { type: 'none', value: 0 } },
      active: { allowMultipleDataPointsSelection: false, filter: { type: 'none', value: 0 } },
    },
    tooltip: {
      style: { fontSize: '12px' },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
    colors: [baseColor, lightColor],
    markers: { colors: [baseColor, lightColor], strokeColors: [baseColor, lightColor], strokeWidth: 3 },
  }
}

export {CardStatistics}
