import React, {useEffect, useRef, useState} from 'react'
import { MessageModel, ChatbotProps } from "../types";
import { sendMessage, sendInitialize } from "../api";
import { adjustTextareaHeight } from "../utils";
import {useLocation} from 'react-router-dom'

export const useChatbot = ({
  endpoint,
  apiUrl,
  publicUrl,
  selectedAgent,
  personaName: initialPersonaName,
  personaAvatar: initialPersonaAvatar,
  setPersonaName,
  setPersonaAvatar,
  token,
}: ChatbotProps) => {
  const [currentEndpoint, setCurrentEndpoint] = useState(""); //endpoint
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState<string>("You");
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [chatCompleted, setChatCompleted] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState(40);
  const [hasContent, setHasContent] = useState(false);
  // @ts-ignore // TODO: check
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const [personaName, setCurrentPersonaName] = useState(initialPersonaName);
  const [personaAvatar, setCurrentPersonaAvatar] = useState(initialPersonaAvatar);

  const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)
  const [selectedCollectionId, setSelectedCollectionId] = useState<string | undefined>(undefined)

  const fileInputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const location = useLocation()

  const isCompanyPage =
    location.pathname.includes('companies/overview') ||
    location.pathname.includes('companies/original') ||
    location.pathname.includes('companies/investor') ||
    location.pathname.includes('companies/document/view');
  const isCollectionPage = location.pathname.includes('documents/view')
  const isDashboardPage = location.pathname.includes('dashboard')
  const isReportPage = location.pathname.includes('reports')
  const isChatbotPage = location.pathname.includes('chatbot')

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    if (userUpdated && userUpdated.id) {
      setUserId(userUpdated.id)
    }
  }, [])

  useEffect(() => {
    setMessages([]);
    setMessage("");
    setSelectedJobId(undefined)
    setSelectedCompanyId(undefined)
    setSelectedCollectionId(undefined)
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'selectedJobId') {
        setSelectedJobId(event.newValue || null)
      } else if (event.key === 'selectedCompanyId') {
        setSelectedCompanyId(event.newValue || null)
      } else if (event.key === 'selectedCollectionId') {
        setSelectedCollectionId(event.newValue || null)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (isReportPage && selectedJobId) {
      setCurrentEndpoint('chatbot-chat');
    } else if (isCompanyPage && selectedCompanyId) {
      setCurrentEndpoint('company');
    } else if (isCollectionPage && selectedCollectionId) {
      setCurrentEndpoint('knowledge');
    } else if (isDashboardPage) {
      setCurrentEndpoint('general');
    } else if (isChatbotPage) {
      setCurrentEndpoint('chat');
    } else {
      setCurrentEndpoint('chat');
      setMessages([])
    }

    if (selectedAgent) {
      setMessages([])
      fetchChatHistory()
    }
  }, [isReportPage, selectedJobId, isCompanyPage, selectedCompanyId, isCollectionPage, selectedCollectionId, isDashboardPage, isChatbotPage, selectedAgent])

  useEffect(() => {
    if (token && currentEndpoint) {
      handleInitialize();
    }
  }, [token, currentEndpoint]);

  useEffect(() => {
    if (scrollRef.current && messages.length > 0) {
      const lastMessageIndex = messages.length - 1;
      const lastMessage = document.getElementById(`message-${lastMessageIndex}`);
      if (lastMessage && (messages[lastMessageIndex].sender === "bot" || messages[lastMessageIndex].sender === "user")) {
        lastMessage.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [messages]);

  const handleInitialize = async () => {
    try {
      setIsLoadingMessage(true);
      const response = await sendInitialize(currentEndpoint, apiUrl, token, selectedJobId, userId, selectedAgent?.id, selectedCompanyId, selectedCollectionId);
      handleBotResponse(response);
    } catch (error) {
      console.error("Initialization failed:", error);
      handleErrorResponse(error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (chatCompleted || (!message.trim() && !selectedFile)) return;

    const userMessageText = selectedFile && message.trim() === "" ? selectedFile.name : message;
    const userMessage: MessageModel = {
      text: userMessageText,
      sender: "user",
      time: new Date().toISOString(),
      user: 1, // Assuming user ID is 1, adjust as needed
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsLoadingMessage(true);

    try {
      const response = await sendMessage(currentEndpoint, apiUrl, token, message, selectedFile, selectedJobId, userId, selectedAgent?.id, selectedCompanyId, selectedCollectionId);
      handleBotResponse(response);
    } catch (error) {
      console.error("Message sending failed:", error);
      handleErrorResponse(error);
    }

    // Clear chat states
    setMessage("");
    setHasContent(false);
    setTextareaHeight(40);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleBotResponse = (response: any) => {
    const botMessage: MessageModel = {
      text: response.data ? response.data : response.message,
      sender: "bot",
      time: new Date().toISOString(),
      user: 0, // Assuming bot ID is 0, adjust as needed
    };

    if (response.agent_name) {
      setCurrentPersonaName(response.agent_name);
      setPersonaName?.(response.agent_name);
    }

    if (response.avatar) {
      setCurrentPersonaAvatar(response.avatar);
      setPersonaAvatar?.(response.avatar);
    }

    if (response.user_name) {
      setUserName(response.user_name);
    }

    if (response.can_upload) {
      setAllowUpload(response.can_upload);
    }

    setMessages((prevMessages) => [...prevMessages, botMessage]);
    setIsLoadingMessage(false);
    setChatCompleted(response.complete);
    localStorage.setItem("messages", JSON.stringify(messages));
  };

  const handleErrorResponse = (error: any) => {
    setIsLoadingMessage(false);
    const errorMessage: MessageModel = {
      text: error.message || "An error occurred",
      sender: "bot",
      time: new Date().toISOString(),
      user: 0,
    };
    setMessages((prevMessages) => [...prevMessages, errorMessage]);
  };

  const handleLike = (index: number) => {
    console.log(`Liked message ${index}`);
    // Implement like functionality
  };

  const handleDislike = (index: number) => {
    console.log(`Disliked message ${index}`);
    // Implement dislike functionality
  };

  const handleReadAloud = (text: string) => {
    const speech = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(speech);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard");
    });
  };

  const fetchChatHistory = () => {
    let url = `${apiUrl}/get-chat-history?all=true&agent_id=${selectedAgent.id}&user_id=${userId}&page=1&items_per_page=100`;

    if (isCompanyPage) {
      url += `&company_id=${selectedCompanyId}`
    } else if (isCollectionPage) {
      url += `&knowledge_base_id=${selectedCollectionId}`
    }

    fetch(url)
      .then(response => response.json())
      .then(response => {
        if (response.status === "success" && response.data) {
          const latestChat = response.data[0]
          if (latestChat && latestChat.history) {
            const formattedMessages = latestChat.history.flatMap(conversation =>
              conversation.map(message => ({
                text: message.content,
                sender: message.user ? 'user' : 'bot',
                avatar: message.avatar,
                agent_name: message.agent,
                time: message.time ? message.time : latestChat.date_started
              }))
            )
            setMessages(formattedMessages)
          }
        } else {
          console.error("Failed to fetch chat history or invalid data structure:", response.message)
        }
      })
      .catch(error => {
        console.error("Error fetching chat history:", error)
      })
  }

  return {
    currentEndpoint,
    userId,
    userName,
    message,
    setMessage,
    messages,
    isLoadingMessage,
    chatCompleted,
    textareaHeight,
    hasContent,
    isRecording,
    selectedFile,
    setSelectedFile,
    dropdownOpen,
    setDropdownOpen,
    allowUpload,
    fileInputRef,
    scrollRef,
    personaName,
    personaAvatar,
    publicUrl,
    handleFormSubmit,
    handleLike,
    handleDislike,
    handleReadAloud,
    handleCopy,
    adjustTextareaHeight,
  };
};
