import React, {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {ModalFeedback} from "../../app/partials/modals/feedback/ModalFeedback";
import {DrawerMessenger} from '../partials'
import FloatingButton from '../../app/partials/widgets/FloatingButton'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  // Check if the current path includes 'companies' or 'documents' or 'dashboard'
  const showMessengerAndButton =
    location.pathname.includes('companies/overview') ||
    location.pathname.includes('companies/original') ||
    location.pathname.includes('companies/investor') ||
    location.pathname.includes('companies/document/view') ||
    location.pathname.includes('documents/view') ||
    location.pathname.includes('dashboard')

  return (
    <PageDataProvider>

      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {showMessengerAndButton && <DrawerMessenger />}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <ModalFeedback show={false} handleClose={() => {}} />
      {/* end:: Modals */}

      <ScrollTop />

      {showMessengerAndButton && <FloatingButton id='kt_drawer_chat_toggle' />}

    </PageDataProvider>
  )
}

export {MasterLayout}