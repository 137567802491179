import React from "react";

export const ChatFooter: React.FC = () => {
  return (
    <div className="col-12 clear clearfix">
      <div className="chat-footer-text my-8 text-xs text-gray-500 text-center fs-7">
        <span className="chat-footer-text ps-4 fw-semibold me-1">
          {new Date().getFullYear().toString()} &copy;
        </span>
        <a
          href="https://altsurge.ai/"
          target="_blank"
          rel="noopener noreferrer"
          className="alt-white text-hover-primary"
        >
          Copyright AltSurge Netherlands - All rights reserved
        </a>
      </div>
    </div>
  );
};
