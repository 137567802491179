import React, {FC} from 'react'

const VerbosePanel: FC = () => {
  return (
    <div className="card w-100 rounded-0 overflow-y-scroll" style={{ width: '350px' }}>
      <div className="card-header">
        <h3 className="card-title">Verbose</h3>
      </div>
      <div className="card-body">

        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:00</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-info fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-normal timeline-content text-muted ps-3'>
              Analyzed user query and initiated document search for relevant information.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:05</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-normal timeline-content text-muted ps-3'>
              Retrieved relevant sections from the knowledge base to address user concerns.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:10</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Content */}
            <div className='timeline-content d-flex'>
              <span className='fw-bold text-gray-800 ps-3'>
                Utilized knowledge base to provide an accurate response.
              </span>
            </div>
            {/* end::Content */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:15</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Desc */}
            <div className='timeline-content fw-bold text-gray-800 ps-3'>
              Identified gaps in the current response and explored additional resources.
            </div>
            {/* end::Desc */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:20</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Desc */}
            <div className='timeline-content fw-bold text-gray-800 ps-3'>
              Executed external tool to fetch real-time data.
            </div>
            {/* end::Desc */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:25</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-info fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-normal timeline-content text-muted ps-3'>
              Processed real-time data and incorporated it into the response.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:30</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='timeline-content fw-normal text-muted ps-3'>
              Generated a detailed response based on the compiled information.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:35</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-warning fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='timeline-content fw-normal text-muted ps-3'>
              Cross-referenced multiple sources to validate information accuracy.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:40</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='timeline-content fw-normal text-muted ps-3'>
              Initiated a feedback loop to verify user satisfaction.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:45</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-info fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-normal timeline-content text-muted ps-3'>
              Analyzed user feedback and identified areas for improvement.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:50</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-danger fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Desc */}
            <div className='timeline-content fw-semibold text-gray-800 ps-3'>
              Adjusted response strategy based on user feedback.
            </div>
            {/* end::Desc */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:55</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='timeline-content fw-normal text-muted ps-3'>
              Finalized and delivered an optimized response to the user.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>09:00</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-normal timeline-content text-muted ps-3'>
              Logged the conversation details for future reference and learning.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Timeline */}

      </div>
    </div>
  )
}

export default VerbosePanel;