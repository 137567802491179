import React, { FC, useEffect, useState } from 'react'
import { KTIcon } from '../../../helpers'
import { IAgentDetails } from '../../../../app/models/AgentModel'
import IPConfig from '../../../../app/store/IPConfig'
import { useLocation } from 'react-router-dom'
import { Chatbot, VerbosePanel } from '../../../../libraries/react-chatbot/src'

const DrawerMessenger: FC = () => {
  const apiUri = IPConfig()
  const location = useLocation()

  const [agentArray, setAgentArray] = useState<IAgentDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [selectedAgent, setSelectedAgent] = useState<IAgentDetails | null>(null);
  const [isVerbosePanelOpen, setIsVerbosePanelOpen] = useState<boolean>(false);

  const isCompanyPage =
    location.pathname.includes('companies/overview') ||
    location.pathname.includes('companies/original') ||
    location.pathname.includes('companies/investor') ||
    location.pathname.includes('companies/document/view');
  const isCollectionPage = location.pathname.includes('documents/view')
  const isDashboardPage = location.pathname.includes('dashboard')

  useEffect(() => {
    fetchAgents();
  }, [pagination.page, searchTerm, sortBy, sortOrder]);

  const fetchAgents = () => {
    setIsLoading(true);

    let url;

    if (isCompanyPage) {
      url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=company`;
    } else if (isCollectionPage) {
      url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=knowledge-base`;
    } else {
      url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=general`;
    }

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    if (sortBy && sortOrder) {
      url += `&sort=${sortBy}&order=${sortOrder}`;
    }

    fetch(url)
      .then(response => response.json())
      .then(response => {
        if (response.data) {
          setAgentArray(response.data);
          if (response.data.length > 0 && !selectedAgent) {
            setSelectedAgent(response.data[0]);
          }
        }
        if (response.payload && response.payload.pagination) {
          setPagination((prev) => ({ ...prev, total: response.payload.pagination.total }));
        } else {
          setAgentArray([]);
        }
      })
      .catch(function (err) {
        console.log("Unable to fetch -", err);
      });

    setIsLoading(false);
  };

  const handleAgentSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const agent = agentArray.find(a => a.id === selectedId);
    setSelectedAgent(agent || null);
  };

  const toggleVerbosePanel = () => {
    setIsVerbosePanelOpen(!isVerbosePanelOpen);
  };

  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width={`{default:'${isVerbosePanelOpen ? 'calc(100vw - 282px)' : '420px'}', 'md': '${isVerbosePanelOpen ? 'calc(100vw - 282px)' : '819px'}'}`}
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
      style={{width: `${isVerbosePanelOpen ? 'calc(100vw - 282px)' : '819px'}`}}
    >
      {isVerbosePanelOpen && <VerbosePanel />}
      <div className={`card w-100 rounded-0 overflow-y-scroll ${isVerbosePanelOpen ? 'border-start' : ''}`} id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <button
              className="btn btn-sm btn-icon btn-active-light-primary me-3"
              onClick={toggleVerbosePanel}
            >
              <KTIcon iconName={isVerbosePanelOpen ? 'arrow-right' : 'arrow-left'} className="fs-2" />
            </button>

            <div className="d-flex justify-content-center flex-column me-3">
              <a href="#" className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                {selectedAgent?.name ? selectedAgent.name : 'Chatbot'}
              </a>

              <div className="mb-0 lh-1">
                <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                <span className="fs-7 fw-bold text-gray-400">Active</span>
              </div>
            </div>
          </div>

          <div className="card-toolbar">
            <div className="me-2">
              <select
                className="form-select form-select-sm"
                onChange={handleAgentSelect}
                value={selectedAgent?.id || ''}
              >
                <option value="">Select an agent</option>
                {agentArray.map(agent => (
                  <option key={agent.id} value={agent.id}>{agent.name}</option>
                ))}
              </select>
            </div>

            <div className="btn btn-sm btn-icon btn-active-light-primary ms-2" id="kt_drawer_chat_close">
              <KTIcon iconName="cross" className="fs-2" />
            </div>
          </div>
        </div>

        <Chatbot isDrawer={true} noFooter={true} apiUrl={apiUri} selectedAgent={selectedAgent} bgColor={'#181C32'} inputColor={'#252b4d'} />
      </div>
    </div>
  )
}

export { DrawerMessenger }