import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {CompanyList} from '../modules/companies/CompanyList'
import {DocumentList} from '../modules/documents/DocumentList'
import {WorkflowWrapper} from '../modules/workflow/WorkflowWrapper'
import {DocumentView} from '../modules/documents/DocumentView'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import UsersPage from '../modules/apps/user-management/UsersPage'
import AccountPage from '../modules/accounts/AccountPage'
import {RoleList} from '../modules/roles/RoleList'
import {PermissionList} from '../modules/permissions/PermissionList'
import {QueueList} from '../modules/queues/QueueList'
import {WorkspaceList} from '../modules/workspaces/WorkspaceList'
import {AgentList} from '../modules/agents/AgentList'
import {JobList} from '../modules/jobs/JobList'
import {RoleEdit} from '../modules/roles/RoleEdit'
import {PermissionEdit} from '../modules/permissions/PermissionEdit'
import {WorkspaceFormAdd} from '../modules/workspaces/WorkspaceFormAdd'
import {QueueOverview} from '../modules/queues/QueueOverview'
import {WorkspaceFormEdit} from '../modules/workspaces/WorkspaceFormEdit'
import {CompanyInvestorView} from '../modules/companies/CompanyInvestorView'
import {AgentFormAdd} from '../modules/agents/AgentFormAdd'
import {AgentFormEdit} from '../modules/agents/AgentFormEdit'
import {DocumentOverview} from '../modules/documents/DocumentOverview'
import {TaskList} from '../modules/tasks/TaskList'
import {TaskOverview} from '../modules/tasks/TaskOverview'
import {CollectionList} from '../modules/collections/CollectionList'
import {CollectionView} from '../modules/collections/CollectionView'

import {useState, useEffect} from 'react'
import {getUserByToken, getUpdatedUser} from '../modules/auth/core/_requests'
import IPConfig from '../store/IPConfig'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

const PrivateRoutes = () => {
  const [user, setUser] = useState<any>(null)
  const CompanyPage = lazy(() => import('../modules/companies/CompanyPage'))

  const apiUrl = IPConfig()
  const [permissionArray, setPermissionArray] = useState(
    JSON.parse(localStorage.getItem('permissions') as string)
  )

  useEffect(() => {
    const token: any = localStorage.getItem('usertoken')
    const userAuthed: any = getUserByToken(token)
    setUser(userAuthed.auth.data)

    const fetchUser = async () => {
      try {
        const userData = await getUpdatedUser()
        setUser(userData)
      } catch (err) {
        console.error('Error fetching user data:', err)
      }
    }

    fetchUser()
  }, [])

  useEffect(() => {
    try {
      if (user && user.role) {
        fetchRole()
      }
    } catch (e) {
      console.error(e)
    }
  }, [user])

  const fetchRole = () => {
    fetch(`${apiUrl}/get-role?id=${user.role.id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data.permission) {
          localStorage.setItem('permissions', JSON.stringify(data.data.permission))
          //Permissions will be overwritten as soon as the user hits this API, local cache
          //falsification shouldn't be an issue
          setPermissionArray(data.data.permission)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  return (
    <Routes>
      {permissionArray &&  (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />

          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='companies' element={<CompanyList />} />
          <Route path='companies/document/view' element={<CompanyInvestorView />} />
          <Route path='tasks' element={<TaskList />} />
          <Route path='tasks/view' element={<TaskOverview />} />

          {/* SECURE ROUTES */}
          {permissionArray.includes('view-roles') && (
            <>
              <Route path='roles' element={<RoleList />} />
              <Route path='roles/edit' element={<RoleEdit />} />
            </>
          )}

          {permissionArray.includes('view-permissions') && (
            <>
              <Route path='permissions' element={<PermissionList />} />
              <Route path='permissions/edit' element={<PermissionEdit />} />
            </>
          )}
          {permissionArray.includes('view-documents') && (
            <>
              <Route path='document/overview' element={<DocumentOverview />} />
              <Route path='document' element={<DocumentView />} />
              <Route path='documents2' element={<DocumentList />} />
              <Route path='documents' element={<CollectionList />} />
              <Route path='documents/view' element={<CollectionView />} />
            </>
          )}
          {/*{permissionArray.includes('view-workflow') && (*/}
            <Route path='workflow' element={<WorkflowWrapper />} />
          {/*)}*/}
          {permissionArray.includes('view-workspaces') && (
            <>
              <Route path='workspaces' element={<WorkspaceList />} />
              <Route path='workspaces/create' element={<WorkspaceFormAdd />} />
              <Route path='workspaces/edit' element={<WorkspaceFormEdit />} />
            </>
          )}
          {permissionArray.includes('view-queues') && (
            <>
              <Route path='queues' element={<QueueList />} />
              <Route path='queues/view' element={<QueueOverview />} />
            </>
          )}
          {permissionArray.includes('view-agents') && (
            <>
              <Route path='agents' element={<AgentList />} />
              <Route path='agents/create' element={<AgentFormAdd />} />
              <Route path='agents/edit' element={<AgentFormEdit />} />
            </>
          )}

          <Route path='jobs' element={<JobList />} />

          {/* Lazy Modules */}
          {permissionArray.includes('view-users') && (
            <Route
              path='apps/user-management/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='companies/*'
            element={
              <SuspensedView>
                <CompanyPage />
              </SuspensedView>
            }
          />
          <Route
            path='account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      )}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
